import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useGeolocated } from 'react-geolocated';
import GOOGLE_MAPS_API_KEY from '../config';
import '../App.css';
import storesData from '../stores.json';
import vsstoresData from '../vsstores_refined.json';

const StoreList = () => {
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  const [nearestStores, setNearestStores] = useState([]);
  const [locationStatus, setLocationStatus] = useState('');
  const [selectedStore, setSelectedStore] = useState(null);
  const [zipcode, setZipcode] = useState('');
  const [showZipcodeInput, setShowZipcodeInput] = useState(false);
  const mapRef = useRef(null);
  const userMarkerRef = useRef(null);
  const storeMarkersRef = useRef([]);
  const highlightedMarkerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (coords) {
      const { latitude, longitude } = coords;
      setLocationStatus('User enabled location');
      findNearestStores(latitude, longitude);
      loadGoogleMapsScript(latitude, longitude);
      setShowZipcodeInput(true);

    } else if (!isGeolocationEnabled || !isGeolocationAvailable) {
      setLocationStatus('User disabled location');
      setShowZipcodeInput(true);
    }
  }, [coords, isGeolocationEnabled, isGeolocationAvailable]);

  const findNearestStores = (latitude, longitude) => {
    // const sortedStores = storesData.map(store => {

    const sortedStores = vsstoresData.map(store => {
      const distance = getDistance(latitude, longitude, store.lat, store.lon);
      return { ...store, distance };
    }).sort((a, b) => a.distance - b.distance);

    setNearestStores(sortedStores);
  };

  const getDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      0.5 - Math.cos(dLat) / 2 +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      (1 - Math.cos(dLon)) / 2;

    return R * 2 * Math.asin(Math.sqrt(a));
  };

  const loadGoogleMapsScript = (latitude, longitude) => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    window.initMap = () => {
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: latitude, lng: longitude },
        zoom: 10,
      });
      mapRef.current = map;

      userMarkerRef.current = new window.google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map,
        title: 'Your Location',
        icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
      });

      storeMarkersRef.current = nearestStores.map(store => {
        return new window.google.maps.Marker({
          position: { lat: store.lat, lng: store.lon },
          map,
          title: store.name,
          icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
        });
      });
    };
  };

  const highlightStoreMarker = (store) => {
    if (highlightedMarkerRef.current) {
      highlightedMarkerRef.current.setMap(null);
    }

    highlightedMarkerRef.current = new window.google.maps.Marker({
      position: { lat: store.lat, lng: store.lon },
      map: mapRef.current,
      title: store.name,
      icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
    });

    mapRef.current.setCenter({ lat: store.lat, lng: store.lon });
    mapRef.current.setZoom(14); // Adjust zoom level as needed

    setSelectedStore(store);
  };

  const handleZipcodeSubmit = () => {
    // Use Google Maps Geocoding API to get latitude and longitude from ZIP code
    const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${GOOGLE_MAPS_API_KEY}`;

    axios.get(geocodingApiUrl)
      .then(response => {
        console.log(response.data); // Log the response for debugging
        if (response.data.results.length > 0) {
          const { lat, lng } = response.data.results[0].geometry.location;
          setShowZipcodeInput(true);
          setLocationStatus('Location set by ZIP code');
          findNearestStores(lat, lng);
          loadGoogleMapsScript(lat, lng);
        } else {
          alert('entered '+zipcode+' Invalid ZIP code. Please try again.');
        }
      })
      .catch(error => {
        console.error('Error fetching coordinates for ZIP code:', error);
        alert('Error fetching coordinates for ZIP code. Please try again.');
      });
  };

  return (
    <div className="App">
      <h1>Nearest Store</h1>
      <p>{locationStatus}</p>
      {showZipcodeInput && (
        <div className="zipcode-input">
          <p>Please enter your ZIP code to find the nearest store:</p>
          <input
            type="text"
            value={zipcode}
            onChange={(e) => setZipcode(e.target.value)}
          />
          <button onClick={handleZipcodeSubmit}>Submit</button>
        </div>
      )}
      <div id="map" className="map"></div>
      <div className="store-list">
        {nearestStores.map(store => (
          <div
            key={store.id}
            className={`store ${selectedStore && selectedStore.id === store.id ? 'selected' : ''}`}
            onMouseEnter={() => highlightStoreMarker(store)}
          >
            <h2>{store.name}</h2>
            <p>Latitude: {store.lat}</p>
            <p>Longitude: {store.lon}</p>
            <p>store id: {store.store_no}</p>

            <p>Distance: {store.distance.toFixed(2)} km</p>
            <button onClick={() => navigate(`/store/${store.store_no}`)}>View Details</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StoreList;
