import React from 'react';
import { useParams } from 'react-router-dom';
import './ProductDetails.css';

const products = [
  { id: 1, name: 'Shoes', price: 100, description: 'Comfortable running shoes', imageUrl: '/images/shoes.png' },
  { id: 2, name: 'Sports Watch', price: 20, description: 'Sports Watch', imageUrl: '/images/sportswatch.png' },
  { id: 3, name: 'Backpack', price: 50, description: 'Durable hiking backpack', imageUrl: '/images/backpack.png' },
  // Add more products as needed
];

const ProductDetails = ({ addToCart }) => {
  const { productId } = useParams();
  const product = products.find(p => p.id === parseInt(productId));

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="product-details">
      <h1>{product.name}</h1>
      <img src={product.imageUrl} alt={product.name} className="product-image" />
      <p>{product.description}</p>
      <p>Price: ${product.price}</p>
      <button onClick={() => addToCart(product)}>Add to Cart</button>
    </div>
  );
};

export default ProductDetails;
