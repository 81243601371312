import React from 'react';
import { useParams } from 'react-router-dom';
import '../App.css'; // Import the CSS file
import vsstoresData from '../vsstores_refined.json';

const stores = [
  { id: 1, name: 'Store 1', lat: 40.7128, lon: -74.0060, address: 'Address 1', phone: '123-456-7890' },
  { id: 2, name: 'Store 2', lat: 34.0522, lon: -118.2437, address: 'Address 2', phone: '987-654-3210' },
  // ...more stores
];

const StoreDetail = () => {
  const { id } = useParams();
  const store = vsstoresData.find(store => store.store_no === parseInt(id));

  return (
    <div className="App">
      {store ? (
        <div className="store">
          <h1>{store.name}</h1>
          {/* <p>Address: {store.address}</p>
          <p>Phone: {store.phone}</p> */}
          <p>Latitude: {store.lat}</p>
          <p>Longitude: {store.lon}</p>
        </div>
      ) : (
        <p>Store not found</p>
      )}
    </div>
  );
};

export default StoreDetail;
