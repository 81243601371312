/* global ApplePaySession */
import React, { useEffect } from 'react';
import './Checkout.css';

const Checkout = ({ cart }) => {
  const getTotalPrice = () => {
    return cart.reduce((total, product) => total + product.price, 0);
  };

  const handleCheckout = () => {
    alert('Checkout completed!');
  };

  const initializeApplePay = () => {
    if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
      const applePayButton = document.createElement('apple-pay-button');
      applePayButton.setAttribute('buttonstyle', 'black');
      applePayButton.setAttribute('type', 'buy');
      applePayButton.addEventListener('click', handleApplePayButtonClick);
      document.getElementById('apple-pay-button-container').appendChild(applePayButton);
    }
  };

  const handleApplePayButtonClick = () => {
    const paymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
      merchantCapabilities: ['supports3DS'],
      total: { label: 'Demo Shop', amount: getTotalPrice().toFixed(2) },
      lineItems: cart.map(product => ({
        label: product.name,
        amount: product.price.toFixed(2),
      })),
    };

    const session = new ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = (event) => {
      const validationURL = event.validationURL;
      fetch('/api/src/functions/validatemerchant', {
        method: 'POST',
        body: JSON.stringify({ validationURL }),
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(merchantSession => {
          session.completeMerchantValidation(merchantSession);
        })
        .catch(error => {
          console.error('Error validating merchant:', error);
          session.abort();
        });
    };

    session.onpaymentauthorized = (event) => {
      const payment = event.payment;
      fetch('/api/src/functions/processpayment', {
        method: 'POST',
        body: JSON.stringify({ payment }),
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            session.completePayment(ApplePaySession.STATUS_SUCCESS);
            handleCheckout();
          } else {
            session.completePayment(ApplePaySession.STATUS_FAILURE);
          }
        })
        .catch(error => {
          console.error('Error processing payment:', error);
          session.completePayment(ApplePaySession.STATUS_FAILURE);
        });
    };

    session.begin();
  };

  useEffect(() => {
    initializeApplePay();
  }, []);

  return (
    <div className="checkout">
      <h1>Checkout</h1>
      {cart.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div>
          {cart.map(product => (
            <div key={product.id} className="checkout-item">
              <img src={product.imageUrl} alt={product.name} className="checkout-item-image" />
              <div className="checkout-item-info">
                <h2>{product.name}</h2>
                <p>Price: ${product.price}</p>
              </div>
            </div>
          ))}
          <h2>Total: ${getTotalPrice()}</h2>
          <div id="apple-pay-button-container"></div>
          <button onClick={handleCheckout}>Complete Checkout</button>
        </div>
      )}
    </div>
  );
};

export default Checkout;
