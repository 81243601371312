import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ProductList.css';

const products = [
  { id: 1, name: 'Shoes', price: 100, description: 'Comfortable running shoes', imageUrl: '/images/shoes.png' },
  { id: 2, name: 'Sports Watch', price: 20, description: 'Sports Watch', imageUrl: '/images/sportswatch.png' },
  { id: 3, name: 'Backpack', price: 50, description: 'Durable hiking backpack', imageUrl: '/images/backpack.png' },
  // Add more products as needed
];

const ProductList = () => {
  const navigate = useNavigate();

  return (
    <div className="product-list">
      <h1>Products</h1>
      <div className="products">
        {products.map(product => (
          <div key={product.id} className="product">
            <img src={product.imageUrl} alt={product.name} className="product-image" />
            <div className="product-info">
              <h2>{product.name}</h2>
              <p>{product.description}</p>
              <p>Price: ${product.price}</p>
              <button onClick={() => navigate(`/product/${product.id}`)}>View Details</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
